export const tableColumns = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "App Name",
    accessor: "app_name",
  },
  {
    header: "Client ID",
    accessor: "client_id",
  },
  {
    header: "Client Secret",
    accessor: "client_secret",
  },
  {
    header: "Client Metadata",
    accessor: "client_metadata",
  },
  {
    header: "Manage",
    accessor: "manage",
  },
];

export const userTableColumns = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Username",
    accessor: "username",
  },
  {
    header: "Contact F",
    accessor: "contact_f",
  },
  {
    header: "Contact L",
    accessor: "contact_l",
  },
  {
    header: "Role Code",
    accessor: "role_code",
  },
];

export const defaultClientMeta = {
  clientName: "",
  appName: "",
  scope: "",
  grantType: "",
  responseType: "",
  logoURL: "",
};

export const apiKeyTableColumns = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Key Name",
    accessor: "api_key_name",
  },
  {
    header: "Key",
    accessor: "api_key_content",
  },
  {
    header: "Active",
    accessor: "active_text",
  },
  {
    header: "Created At",
    accessor: "created_at",
  },
  {
    header: "Manage",
    accessor: "manage",
  },
];