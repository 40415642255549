import React from "react";
import { Table } from "react-bootstrap";

const GenericTable = ({ columns, rows }) => {
  return (
    <Table striped bordered hover responsive size="sm">
      <thead>
        <tr>
          {columns.map(({ header }, key) => {
            return <th key={key}>{header}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, key) => {
          return (
            <tr key={key}>
              {columns.map(({ accessor, display }, key) => {
                return (
                  <td key={key}>
                    {display ? display(row) : row[accessor]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default GenericTable;
