import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import { renderRoutes } from "utils/layout";
import { selectUser } from "global_store/features/authen/authenSlice";
import { useSelector } from "react-redux";
import NotFoundPage from "pages/page/NotFoundPage";

const OAuth = () => {
  const user = useSelector(selectUser);
  return (
    <div>
      <Switch>
          {renderRoutes(routes, "/oauth", user?.role_code || "")}
          <Redirect exact path="/oauth" to="/oauth/signin" />
          <Route path="/oauth/*" component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default OAuth;
