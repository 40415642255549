import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  api_key_name: yup.string().required("โปรดใส่ API Key Name"),
});

const AddAPIKeyModal = ({ showModal, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
  };

  const onFormSubmit = async (data) => {
    await onSubmit(data.api_key_name);
    reset();
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create API Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group size="lg" controlId="api_key_name">
            <Form.Label>API Key Name *</Form.Label>
            <Form.Control
              placeholder="Enter API Key Name"
              aria-label="api_key_name"
              type="text"
              {...register("api_key_name")}
              isInvalid={errors.api_key_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.api_key_name?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            Create
          </Button>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddAPIKeyModal;
