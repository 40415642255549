import AdminLogin from "pages/page/AdminLogin";
import DoctorLogin from "pages/page/DoctorLogin";
import OAuthSignin from "pages/oauth/OAuthSignin";
import OAuthAuthorize from "pages/oauth/OAuthAuthorize";
import AdminHome from "pages/console/AdminHome";
import DoctorProfile from "pages/console/DoctorProfile";
import ContinueOnDevice from "pages/page/ContinueOnDevice";
import ManageUser from "pages/console/ManageUser";
import ManageAPIKey from "pages/console/ManageAPIKey";

const routes = [
  {
    path: "/admin-login",
    name: "admin login",
    component: AdminLogin,
    layout: "/page",
    isPrivate: false,
  },
  {
    path: "/doctor-login",
    name: "doctor login",
    component: DoctorLogin,
    layout: "/page",
    isPrivate: false,
  },
  {
    path: "/signin",
    name: "oauth signin",
    component: OAuthSignin,
    layout: "/oauth",
    isPrivate: false,
  },
  {
    path: "/authorize",
    name: "oauth authorize",
    component: OAuthAuthorize,
    layout: "/oauth",
    isPrivate: true,
    roles: ["doctor"],
  },
  {
    path: "/manage-client",
    name: "manage client",
    component: AdminHome,
    layout: "/console",
    isPrivate: true,
    roles: ["admin"],
  },
  {
    path: "/manage-api-key",
    name: "manage api key",
    component: ManageAPIKey,
    layout: "/console",
    isPrivate: true,
    roles: ["admin"],
  },
  {
    path: "/doctor-profile",
    name: "doctor profile",
    component: DoctorProfile,
    layout: "/console",
    isPrivate: true,
    roles: ["doctor"],
  },
  {
    path: "/continue-on-device",
    name: "continue on device",
    component: ContinueOnDevice,
    layout: "/page",
    isPrivate: true,
    roles: ["doctor"],
  },
  {
    path: "/manage-user",
    name: "manage user",
    component: ManageUser,
    layout: "/console",
    isPrivate: true,
    roles: ["admin"],
  },
];

export default routes;
