import React, { useState } from 'react';
import { Button, Row, Col, Modal } from "react-bootstrap";

const Footer = props => {
  const [show, setShow] = useState(false);

  const setModalShow = () => {
    setShow(!show);
  };

  return (
    <>
      <div className="sectionCenter">
        <p style={{ cursor: "pointer" }} onClick={() => setModalShow()}>
          FAQ
        </p>
      </div>
      <p className="sectionCenter">
        ดำเนินการโดย แพทยสภา <a href="tel:+6625901886">02-5901887</a>
      </p>
      <p className="sectionCenter">
        Browser Support
      </p>
      <Row className="sectionCenter">
        <Col>
          <img src="https://doctor.tmc.or.th/img/01-gg.png" alt="Google Chrome" width="24" title="Google Chrome" />
          <br />Chrome<br />v.72+
        </Col>
        <Col>
          <img src="https://doctor.tmc.or.th/img/02-ff.png" alt="Mozilla Firefox" width="24" title="Mozilla Firefox" />
          <br />Firefox<br />v.60+
        </Col>
        <Col>
          <img src="https://doctor.tmc.or.th/img/03-sr.png" alt="Safari" width="24" title="Safari" />
          <br />Safari<br />v.12+
        </Col>
      </Row>

      <Modal
        show={show}
        size="lg"
        centered
        onHide={() => setModalShow()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            FAQ
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textIndent: "1.5rem" }}>
            ด้วยคณะกรรมการแพทยสภา วาระ พ.ศ. 2562 – 2564 มีนโยบายอำนวยความสะดวกให้สมาชิกแพทยสภาที่เข้าถึงระบบออนไลน์ สามารถเข้าสู่การใช้บริการทางอิเล็กทรอนิกส์ในด้านต่างๆ ของแพทยสภา ได้อย่างรวดเร็วและมีประสิทธิภาพ</p>
          <p style={{ textIndent: "1.5rem" }}>ในการนี้แพทยสภา จึงได้จัดทำโครงการนำร่องให้สมาชิกแพทย์ทั่วประเทศ เข้าถึงระบบข้อมูลของตนเองได้ โดยผ่านช่องทาง Online ทั้งนี้สมาชิกเริ่มต้นต้องลงทะเบียนเพื่อสร้าง Pin Code เฉพาะตน ในครั้งแรกเพื่อรักษาความปลอดภัยของตนเอง ก่อนใช้บริการธุรกรรมของแพทยสภาได้ตลอดไป อาทิ</p>
          <ul style={{ textIndent: "1.5rem", listStyleType: "none" }}>
            <li>1. เพื่อตรวจสอบอัพเดทข้อมูลตนเองและใช้บริการธุรกรรมอื่นๆ ของแพทยสภา</li>
            <li>2. เพื่อรองรับการเลือกตั้งกรรมการแพทยสภาและกรรมการราชวิทยาลัยฯ ระบบ Online</li>
            <li>3. เพื่อรองรับระบบการแพทย์ทางไกล หรือโทรเวช (Telemedicine)</li>
            <li>4. เพื่อเก็บคะแนนการศึกษาต่อเนื่อง (CME) ผ่านระบบออนไลน์ เป็นต้น</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setModalShow()}>ปิดการทำงาน</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}


export default Footer;