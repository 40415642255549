import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo_md from "assets/img/mdeServ2.png";
import {
  Card,
  Spinner,
  Tab,
  Tabs,
  Image,
  Form,
  InputGroup,
  Alert,
} from "react-bootstrap";
import Footer from "components/Footer/Footer";
import { requestDoctorLogin, requestLoginQR } from "services/authen";
import {
  authorizeOAuthClient,
  getOAuthClientById,
  getUserOAuthConsentStatus,
} from "services/oauth_client";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccessToken,
  setUser,
  selectUser,
} from "global_store/features/authen/authenSlice";
import jwtDecode from "jwt-decode";
// import { websocketBaseURL } from "config";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Icon } from "@iconify/react";
import UserAlt from "@iconify/icons-fa-solid/user-alt";
import Key from "@iconify/icons-fa-solid/key";
import LoginButton from "components/Button/LoginButton";
import ForgetPincode from "components/Button/ForgetPincode";
import NoPinCode from "components/Button/NoPinCode";
import QRTimer from "components/Timer/QRTimer";
import { QR_SESSION_SECONDS } from "config";

const schema = yup.object().shape({
  username: yup.string().required("โปรดใส่เลข ว."),
  password: yup.string().required("โปรดใส่ PIN CODE"),
});

const OAuthSignin = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { search } = useLocation();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [isLoadingQR, setIsLoadingQR] = React.useState(true);
  const [qrcode, setQRcode] = React.useState(null);
  const [websocket, setWebSocket] = React.useState(null);
  const [blurQR, setBlurQR] = React.useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [responseType, setResponseType] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  const [redirectURI, setRedirectURI] = React.useState(null);

  const [clientUserId, setClientUserId] = React.useState(null);
  const [clientMeta, setClientMeta] = React.useState(null);

  const onRxMessage = React.useCallback(
    (e) => {
      try {
        const { message_type, payload } = JSON.parse(e.data);
        if (message_type === "CLAIM_QR" && payload.qr_scanned) {
          setBlurQR(true);
        } else if (message_type === "LOGIN_QR" && payload.login_success) {
          const tokenContent = jwtDecode(payload.access_token);
          console.log(tokenContent);
          dispatch(setAccessToken(payload.access_token));
          dispatch(setUser(tokenContent.user));
        }
      } catch (err) {
        console.log(`Non JSON format message received: ${e.data}`);
      }
    },
    [dispatch]
  );

  const getSocketInfo = async (qrType = undefined) => {
    const { data: socketInfo } = await requestLoginQR(qrType);
    if (socketInfo) {
      setQRcode(socketInfo.qrcode);
      // Connect to Websocket
      const newWebSocket = new WebSocket(
        `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.hostname
        }:${window.location.port}/api/oauth/ws/${socketInfo.group_id}`
      );
      newWebSocket.onmessage = onRxMessage;
      setWebSocket(newWebSocket);
    }
  };

  const handleSelectTab = async (eventKey) => {
    if (eventKey === "qr") {
      setIsLoadingQR(true);
      await getSocketInfo(undefined);
      setIsLoadingQR(false);
      setBlurQR(false);
    } else if (eventKey === "qr-doctor-app") {
      setIsLoadingQR(true);
      await getSocketInfo("doctor_app");
      setIsLoadingQR(false);
      setBlurQR(false);
    } else {
      // Disconnect websocket
      if (websocket) {
        websocket.close();
      }
    }
  };

  const handleResetQR = () => {
    if (websocket) {
      websocket.close();
    }
    setIsLoadingQR(true);
    getSocketInfo("doctor_app");
    setIsLoadingQR(false);
    setBlurQR(false);
  };

  const handleQRTimeout = () => {
    setBlurQR(true);
    if (websocket) {
      websocket.close();
    }
  };

  const onSubmit = async (data) => {
    setLoginFailed(false);
    setIsProcessing(true);
    const { success, data: res, error } = await requestDoctorLogin(data, null);
    if (success && res) {
      const tokenContent = jwtDecode(res.access_token);
      dispatch(setAccessToken(res.access_token));
      dispatch(setUser(tokenContent.user));
    } else {
      console.log(error);
      setLoginFailed(true);
    }
    setIsProcessing(false);
  };

  const loadClientDetails = React.useCallback(async () => {
    if (user && responseType && clientId && state) {
      const {
        success,
        data: clientData,
        error,
      } = await getOAuthClientById(clientId);
      if (success && clientData) {
        const clientMeta = JSON.parse(clientData.client_metadata);
        setClientUserId(clientData.user_id);
        setClientMeta(clientMeta);
        // setAppName(clientMeta.app_name);
        // setScopeList(clientMeta.scope.split(","));
        // setClientLogoURL(clientMeta.logo_url);
      } else {
        console.log(error);
      }
    } else {
      console.log("Invalid Parameters");
    }
  }, [user, responseType, clientId, state]);


  const handleAuthorize = React.useCallback(async () => {
    const { success, data, error } = await authorizeOAuthClient({
      clientId,
      clientMeta,
      state,
      redirectURI,
      clientUserId,
    });
    if (success && data) {
      window.location.replace(
        `${redirectURI}?code=${data.code}&state=${state}`
      );
    } else {
      console.log("Error occured with authorization process");
      console.log(error);
    }
  }, [clientId, clientMeta, clientUserId, redirectURI, state]);

  const checkConsented = React.useCallback(async () => {
    if (user && clientId && clientId && clientMeta && state && redirectURI && clientUserId) {
      const { success, data } = await getUserOAuthConsentStatus(clientId);
      if (success && data && data.consent) {
        await handleAuthorize();
      } else {
        history.push(`/oauth/authorize${search}`);
      }
    }
  }, [user, clientId, clientId, clientMeta, clientUserId, redirectURI, state, history, search, handleAuthorize]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search);
    setResponseType(queryParams.get("response_type"));
    setState(queryParams.get("state"));
    setClientId(queryParams.get("client_id"));
    setRedirectURI(queryParams.get("redirect_uri"));
  }, [search]);

  React.useEffect(async () => {
    await loadClientDetails();
  }, [loadClientDetails]);

  React.useEffect(async () => {
    await checkConsented();
  }, [checkConsented]);

  React.useEffect(() => {
    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, [websocket]);

  return (
    <div className="bg-signin">
      <div className="v-center">
        <Card className="text-center">
          <Card.Header>
            <div className="sectionCenter">
              <img src={logo_md} alt="logo_md" />
            </div>
          </Card.Header>
          <Card.Body>
            <Tabs defaultActiveKey="home" onSelect={handleSelectTab} justify>
              <Tab eventKey="home" title="Username Login" style={{ marginTop: 20 }}>
                
                {loginFailed && (
                  <Alert
                    variant="danger"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <strong>ข้อมูลไม่ถูกต้อง !</strong> DOCTOR ID. หรือรหัส PIN
                    CODE ไม่ถูกต้อง
                  </Alert>
                )}
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="username">
                      <Icon icon={UserAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Doctor ID (เลข ว.)"
                      aria-label="Username"
                      type="Text"
                      {...register("username")}
                      isInvalid={errors.username}
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <InputGroup.Text id="password">
                      <Icon icon={Key} />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="PIN CODE"
                      aria-label="PINCODE"
                      type="password"
                      {...register("password")}
                      isInvalid={errors.password}
                    />
                  </InputGroup>
                  <div className="d-grid gap-2">
                    <LoginButton isProcessing={isProcessing} />
                  </div>
                  <div className="my-2">
                    <ForgetPincode />
                  </div>
                  <div className="mt-5 mb-1">
                    <NoPinCode />
                  </div>
                </Form>
              </Tab>
              <Tab
                eventKey="qr-doctor-app"
                title="QR Code Login"
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  minHeight: 337,
                }}
              >
                {isLoadingQR ? (
                  <div
                    style={{ minHeight: 170 }}
                    className="d-flex justify-content-center align-items-end"
                  >
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div>
                    <Image
                      src={`data:image/png;base64,${qrcode}`}
                      style={{
                        height: "300px",
                        filter: blurQR ? "blur(8px)" : "",
                      }}
                    />
                    <QRTimer
                      onTimeout={handleQRTimeout}
                      onReset={handleResetQR}
                      durationSeconds={QR_SESSION_SECONDS}
                    />
                  </div>
                )}
              </Tab>
            </Tabs>
          </Card.Body>
          <Card.Footer>
            <Footer />
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default OAuthSignin;
