import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Card, Spinner, Button, ButtonGroup } from "react-bootstrap";
import {
  authorizeOAuthClient,
  getOAuthClientById,
} from "services/oauth_client";
import { requestLogout } from "services/authen";
import {
  setUser,
  setAccessToken,
} from "global_store/features/authen/authenSlice";
import { useDispatch } from "react-redux";

const OAuthAuthorize = () => {
  const isProcessing = false;
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const [responseType, setResponseType] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  const [redirectURI, setRedirectURI] = React.useState(null);

  const [clientUserId, setClientUserId] = React.useState(null);
  const [clientMeta, setClientMeta] = React.useState(null);
  const [appName, setAppName] = React.useState(null); //"HIE";
  const [scopeList, setScopeList] = React.useState(null); //["เลข วอ.", "ชื่อ", "ความชำนาญ"];
  const [clientLogoURL, setClientLogoURL] = React.useState(null); //"/static/media/logo512.8d5e02ea.png";

  const loadClientDetails = React.useCallback(async () => {
    if (responseType && clientId && state) {
      const {
        success,
        data: clientData,
        error,
      } = await getOAuthClientById(clientId);
      if (success && clientData) {
        const clientMeta = JSON.parse(clientData.client_metadata);
        setClientUserId(clientData.user_id);
        setClientMeta(clientMeta);
        setAppName(clientMeta.app_name);
        setScopeList(clientMeta.scope.split(","));
        setClientLogoURL(clientMeta.logo_url);
      } else {
        console.log(error);
      }
    } else {
      console.log("Invalid Parameters");
    }
  }, [responseType, clientId, state]);

  const handleAuthorize = React.useCallback(async () => {
    const { success, data, error } = await authorizeOAuthClient({
      clientId,
      clientMeta,
      state,
      redirectURI,
      clientUserId,
    });
    if (success && data) {
      window.location.replace(
        `${redirectURI}?code=${data.code}&state=${state}`
      );
    } else {
      console.log("Error occured with authorization process");
      console.log(error);
    }
  }, [clientId, clientMeta, clientUserId, redirectURI, state]);

  const handleCancel = () => {
    requestLogout();
    dispatch(setUser(null));
    dispatch(setAccessToken(null));
    history.push(`/oauth/signin${search}`);
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search);
    setResponseType(queryParams.get("response_type"));
    setState(queryParams.get("state"));
    setClientId(queryParams.get("client_id"));
    setRedirectURI(queryParams.get("redirect_uri"));
  }, [search]);

  React.useEffect(() => {
    loadClientDetails();
  }, [loadClientDetails]);

  return (
    <div className="bg-signin">
      <div>
        <Card style={{ width: "30rem", height: "30rem" }}>
          <Card.Body style={{ textAlign: "center", paddingInline: "3rem" }}>
            {isProcessing ? (
              <>
                <Spinner animation="border" variant="success" />{" "}
                <p style={{ color: "#009c9f" }}>Loading ... </p>
              </>
            ) : (
              <>
                <div style={{ padding: "2rem" }}>
                  {clientLogoURL && (
                    <img
                      style={{ height: "110px" }}
                      src={clientLogoURL}
                      alt="Client Logo"
                    />
                  )}
                </div>
                <div className="sectionText">
                  {appName && (
                    <>
                      <p> {appName} จะได้รับข้อมูลดังต่อไปนี้ : </p>
                      <ul
                        style={{
                          display: "table",
                          margin: "0 auto",
                          paddingLeft: "0",
                        }}
                      >
                        {scopeList &&
                          scopeList.map((item, index) => {
                            return (
                              <li
                                style={{
                                  textAlign: "left",
                                  paddingBottom: "5px",
                                }}
                                key={index}
                              >
                                {item}
                              </li>
                            );
                          })}
                      </ul>
                    </>
                  )}
                </div>
                <div>
                  <ButtonGroup>
                    <Button
                      className="btnConsent"
                      style={{ background: "rgb(27, 41, 66)" }}
                      block="true"
                      size="lg"
                      onClick={handleAuthorize}
                    >
                      {" "}
                      ยินยอม{" "}
                    </Button>
                    <Button
                      variant="outline-secondary"
                      style={{ cursor: "pointer", color: "#a1a1a1" }}
                      onClick={handleCancel}
                    >
                      {" "}
                      ยกเลิก{" "}
                    </Button>
                  </ButtonGroup>
                </div>
                <div>
                  <a
                    style={{ cursor: "pointer", color: "#009c9f" }}
                    href="https://www.healthlink.go.th/privacy-policy/#doctor-PP"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    นโยบายความเป็นส่วนตัว
                  </a>
                  &nbsp;และ
                  <a
                    style={{ cursor: "pointer", color: "#009c9f" }}
                    href="https://www.healthlink.go.th/terms-conditions/#doctor-TC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ข้อกำหนด
                  </a>
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default OAuthAuthorize;
