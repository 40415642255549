import axios from "axios";
import { getDefaultRequestHeaders } from "utils";

export const requestAdminLogin = async ({ username, password }) => {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  try {
    const res = await axios.post("/api/auth/login", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        login_type: "general",
      },
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const requestLogout = async () => {
  try {
    const res = await axios.get("/api/auth/logout");
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const getAccessToken = async () => {
  try {
    const res = await axios.get("/api/auth/access-token");
    return { success: true, data: res.data, error: null };
  } catch (err) {
    console.log(err);
    return { success: false, data: null, error: err.response.data };
  }
};

export const requestDoctorLogin = async ({ username, password }, groupId) => {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  try {
    const res = await axios.post("/api/auth/login", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        login_type: "doctor",
        group_id: groupId,
      },
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const requestLoginQR = async (qrType) => {
  try {
    const res = await axios.get("/api/oauth/qrcode", {
      params: {
        code_type: qrType,
      },
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const claimQRLogin = async (groupId) => {
  try {
    const res = await axios.post("/api/oauth/claim-qr", groupId);
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const authorizeQRLogin = async (groupId) => {
  try {
    const res = await axios.post("/api/oauth/authorize-qr-login", groupId, {
      headers: await getDefaultRequestHeaders(true),
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};
