import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import GenericTable from "components/GenericTable/GenericTable";
import { tableColumns, defaultClientMeta } from "config/admin";
import {
  getOAuthClientList,
  createOAuthClient,
  updateOAuthClient,
  deleteOAuthClient,
} from "services/oauth_client";
import SecretText from "components/Text/SecretText";

const AdminProfile = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [clientId, setClientId] = React.useState();
  const [clientList, setClientList] = React.useState([]);
  const [clientName, setClientName] = React.useState(
    defaultClientMeta.clientName
  );
  const [appName, setAppName] = React.useState(defaultClientMeta.appName);
  const [scope, setScope] = React.useState(defaultClientMeta.scope);
  const [grantType, setGrantType] = React.useState(defaultClientMeta.grantType);
  const [responseType, setResponseType] = React.useState(
    defaultClientMeta.responseType
  );
  const [logoURL, setLogoURL] = React.useState(defaultClientMeta.logoURL);
  const [actionType, setActionType] = React.useState("create");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const resetClientMetaForm = () => {
    setClientName(defaultClientMeta.clientName);
    setAppName(defaultClientMeta.appName);
    setScope(defaultClientMeta.scope);
    setGrantType(defaultClientMeta.grantType);
    setResponseType(defaultClientMeta.responseType);
    setLogoURL(defaultClientMeta.logoURL);
  };

  const toggleModalShow = () => {
    setModalShow(!modalShow);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const loadClientList = React.useCallback(async () => {
    const { success, data } = await getOAuthClientList();
    console.log(success);
    console.log(data);
    if (success && data) {
      setClientList(data);
    } else {
      console.log("Error getting oauth client list.");
    }
  }, []);

  React.useEffect(() => {
    loadClientList();
  }, [loadClientList]);

  const formatClientList = (rawClientList) => {
    return rawClientList.map((client) => {
      return {
        ...client,
        client_secret: <SecretText text={client.client_secret} />,
        client_metadata: (
          <>
            <Button
              variant="warning"
              onClick={() => {
                setActionType("update");
                setClientId(client.client_id);
                setClientName(client.client_name);
                setAppName(client.app_name);
                setScope(client.scope);
                setGrantType(client.grant_type);
                setResponseType(client.response_type);
                setLogoURL(client.logo_url);
                toggleModalShow();
              }}
            >
              Show
            </Button>
          </>
        ),
        manage: (
          <>
            <Button
              variant="danger"
              onClick={() => {
                setActionType("delete");
                setClientId(client.client_id);
                toggleDeleteModal();
              }}
            >
              Delete
            </Button>
          </>
        ),
      };
    });
  };

  const handleCreateClient = async () => {
    const clientData = {
      client_name: clientName,
      app_name: appName,
      scope: scope,
      grant_type: grantType,
      response_type: responseType,
      logo_url: logoURL,
    };

    const { success, data, error } = await createOAuthClient(clientData);
    if (success) {
      console.log(data);
    } else {
      console.log(error);
    }
    toggleModalShow();
    await loadClientList();
  };

  const handleUpdateClient = async () => {
    const clientData = {
      client_name: clientName,
      app_name: appName,
      scope: scope,
      grant_type: grantType,
      response_type: responseType,
      logo_url: logoURL,
    };

    const { success, data, error } = await updateOAuthClient(
      clientData,
      clientId
    );
    if (success) {
      console.log(data);
    } else {
      console.log(error);
    }
    toggleModalShow();
    loadClientList();
  };

  const handleDeleteClient = async () => {
    const clientData = {
      client_id: clientId,
    };

    const { success, data, error } = await deleteOAuthClient(clientData);

    if (success) {
      console.log(data);
    } else {
      console.log(error);
    }
    toggleDeleteModal();
    loadClientList();
  };

  return (
    <div>
      <div className="App container py-3">
        <div className="Home">
          <div className="header">
            <Button
              onClick={() => {
                setClientId(null);
                setActionType("create");
                resetClientMetaForm();
                toggleModalShow();
              }}
            >
              ADD
            </Button>
            <Button
              onClick={() => {
                loadClientList();
              }}
            >
              Refresh Client List
            </Button>
          </div>
          <Modal
            show={showDeleteModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
              toggleDeleteModal();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Confirm DELETE Oauth Client
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>ต้องการลบ OAuth Client ใช่หรือไม่</Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => {
                  handleDeleteClient();
                }}
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  toggleDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
              toggleModalShow();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Create Oauth Clients
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group size="lg" controlId="clientName">
                <Form.Label>Client Name : </Form.Label>
                <Form.Control
                  autoFocus
                  value={clientName}
                  type="text"
                  placeholder="Enter Client Name"
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="appName">
                <Form.Label>Application Name : </Form.Label>
                <Form.Control
                  type="text"
                  value={appName}
                  placeholder="Enter Applcation Name"
                  onChange={(e) => {
                    setAppName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="scopes">
                <Form.Label>Allowed Scopes :</Form.Label>
                <Form.Control
                  type="text"
                  value={scope}
                  placeholder="Example 'profile photo expert'"
                  onChange={(e) => {
                    setScope(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="grantTypes">
                <Form.Label>Grant Types :</Form.Label>
                <Form.Control
                  type="text"
                  value={grantType}
                  placeholder="Example Authorization_code"
                  onChange={(e) => {
                    setGrantType(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="resTypes">
                <Form.Label>Response Types :</Form.Label>
                <Form.Control
                  type="text"
                  value={responseType}
                  placeholder="Example code"
                  onChange={(e) => {
                    setResponseType(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="LogoURL">
                <Form.Label>Logo URL : </Form.Label>
                <Form.Control
                  type="text"
                  value={logoURL}
                  placeholder="Enter Logo URL"
                  onChange={(e) => {
                    setLogoURL(e.target.value);
                  }}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => {
                  toggleModalShow();
                }}
              >
                Close
              </Button>
              {actionType === "create" ? (
                <Button
                  onClick={() => {
                    handleCreateClient();
                  }}
                  disabled={actionType !== "create"}
                >
                  Create
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    handleUpdateClient();
                  }}
                  disabled={actionType !== "update"}
                >
                  Update
                </Button>
              )}
            </Modal.Footer>
          </Modal>

          <div className="body">
            <GenericTable
              columns={tableColumns}
              rows={formatClientList(clientList)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
