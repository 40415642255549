import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NotFoundPage from "pages/page/NotFoundPage";
import OAuthLayout from "layouts/OAuth/OAuth";
import PageLayout from "layouts/Page/Page";
import ConsoleLayout from "layouts/Console/Console";
import SessionExpiredModal from "components/Modal/SessionExpiredModal";
import { useDispatch } from "react-redux";
import { getAccessToken } from "services/authen";
import {
  setAccessToken,
  setUser,
} from "global_store/features/authen/authenSlice";
import jwtDecode from "jwt-decode";

function App() {
  const dispatch = useDispatch();

  const accessTokenRoutine = React.useCallback(async () => {
    const { success, data, error } = await getAccessToken();
    if (success && data?.status === "success") {
      const { status, access_token } = data;
      if (status === "success") {
        const tokenContent = jwtDecode(access_token);
        dispatch(setAccessToken(access_token));
        dispatch(setUser(tokenContent.user));
      }
    } else {
      console.log(error);
    }
  }, [dispatch]);

  React.useEffect(() => {
    accessTokenRoutine();
  }, [accessTokenRoutine]);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/oauth" component={OAuthLayout} />
          <Route path="/page" component={PageLayout} />
          <Route path="/console" component={ConsoleLayout} />
          <Redirect exact path="/" to="/page/doctor-login" />
          <Route path="*" component={NotFoundPage} />
        </Switch>
        <SessionExpiredModal />
      </BrowserRouter>
    </div>
  );
}

export default App;
