import React from "react";
import Alert from "react-bootstrap/Alert";

const RequireLoginPage = () => {
  return (
    <div>
      <Alert variant="danger">Please login to visit this page.</Alert>
    </div>
  );
};

export default RequireLoginPage;
