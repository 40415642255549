import axios from "axios";
import jwtDecode from "jwt-decode";
import { getDefaultRequestHeaders } from "utils";
import { getToken } from "utils/authen";

export const getOAuthClientList = async () => {
  try {
    const res = await axios.get("/api/oauth/client-list", {
      headers: await getDefaultRequestHeaders(true),
    });
    const formattedData = res.data.map((client) => {
      const parsedMeta = JSON.parse(client.client_metadata);
      return {
        ...client,
        ...parsedMeta,
      };
    });
    return { success: true, data: formattedData, error: null };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const createOAuthClient = async (data) => {
  try {
    const res = await axios.post(
      "/api/oauth/client-create",
      {
        client_metadata: JSON.stringify(data),
      },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const updateOAuthClient = async (data, clientId) => {
  try {
    const res = await axios.post(
      "/api/oauth/client-update",
      {
        client_metadata: JSON.stringify(data),
      },
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          client_id: clientId,
        },
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const deleteOAuthClient = async (data) => {
  try {
    const res = await axios.post("/api/oauth/client-delete", data, {
      headers: await getDefaultRequestHeaders(true),
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const getOAuthClientById = async (clientId) => {
  try {
    const res = await axios.get("/api/oauth/client", {
      headers: await getDefaultRequestHeaders(true),
      params: {
        client_id: clientId,
      },
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const authorizeOAuthClient = async (data) => {
  const { user } = jwtDecode(getToken());
  const nonceObject = {
    doctor_code: user.username,
    prefix: user.other_info.prefix,
    firstname: user.other_info.firstname,
    lastname: user.other_info.lastname,
  };
  const requestBody = {
    doctor_code: user.username,
    client_id: data.clientId,
    redirect_uri: data.redirectURI,
    response_type: "code",
    scope: data.clientMeta.scope,
    nonce: JSON.stringify(nonceObject),
    code_challenge: "",
    code_challenge_method: "",
    user_id: data.clientUserId,
    state: data.state,
  };
  try {
    const res = await axios.post("/api/oauth/authorize", requestBody, {
      headers: await getDefaultRequestHeaders(true),
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const getUserOAuthConsentStatus = async (clientId) => {
  const { user } = jwtDecode(getToken());
  try {
    const res = await axios.get("/api/oauth/user-consent-status", {
      headers: await getDefaultRequestHeaders(true),
      params: {
        client_id: clientId,
        doctor_code: user.username,
      },
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};
