import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import GenericTable from "components/GenericTable/GenericTable";
import { apiKeyTableColumns } from "config/admin";
import { getAPIKeyList, createAPIKey, updateAPIKey } from "services/api_key";
import SecretText from "components/Text/SecretText";
import AddAPIKeyModal from "components/Modal/AddAPIKeyModal";

const ManageAPIKey = () => {
  const [apiKeyList, setAPIKeyList] = React.useState([]);
  const [showCreateAPIKeyModal, setShowCreateAPIKeyModal] = React.useState(false);

  const loadAPIKeyList = React.useCallback(async () => {
    const { success, data, error } = await getAPIKeyList();
    if (success) {
      setAPIKeyList(data);
    } else {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    loadAPIKeyList();
  }, [loadAPIKeyList]);

  const handleUpdateAPIKey = async (id, active) => {
    const { success, error } = await updateAPIKey(id, active);
    if (success) {
      loadAPIKeyList();
    } else {
      console.log(error);
    }
  };

  const handleShowCreateAPIKeyModal = () => {
    setShowCreateAPIKeyModal(true);
  }

  const handleCloseCreateAPIKeyModal = () => {
    setShowCreateAPIKeyModal(false);
  }

  const handleCreateAPIKey = async (apiKeyName) => {
    const { success, error } = await createAPIKey(apiKeyName);
    if (success) {
      loadAPIKeyList();
      handleCloseCreateAPIKeyModal();
    } else {
      console.log(error);
    }
  }

  const formatAPIKeyList = (rawAPIKeyList) => {
    return rawAPIKeyList
      .map((apiKey) => {
        return {
          ...apiKey,
          api_key_content: <SecretText text={apiKey.api_key_content} />,
          active_text: apiKey.active ? "True" : "False",
          manage: (
            <>
              {apiKey.active ? (
                <Button
                  variant="danger"
                  onClick={() => {
                    handleUpdateAPIKey(apiKey.id, false);
                  }}
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  variant="success"
                  onClick={() => {
                    handleUpdateAPIKey(apiKey.id, true);
                  }}
                >
                  Activate
                </Button>
              )}
            </>
          ),
        };
      })
      .sort((a, b) => a.id - b.id);
  };

  return (
    <div>
      <Container>
        <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
          <h1>API Key Management</h1>
        </Row>
        <Row>
          <Col>
            <Button onClick={handleShowCreateAPIKeyModal}>Add API Key</Button>
          </Col>
        </Row>
        <Row>
          <GenericTable
            columns={apiKeyTableColumns}
            rows={formatAPIKeyList(apiKeyList || [])}
          />
        </Row>
      </Container>
      <AddAPIKeyModal showModal={showCreateAPIKeyModal} onClose={handleCloseCreateAPIKeyModal} onSubmit={handleCreateAPIKey} />
    </div>
  );
};

export default ManageAPIKey;
