import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import GenericTable from "components/GenericTable/GenericTable";
import { userTableColumns } from "config/admin";
import { getUserList } from "services/user";

const ManageUser = () => {
  const [password, setPassword] = React.useState("");
  const [hashedPassword, setHashedPassword] = React.useState("");
  const [userList, setUserList] = React.useState([]);

  const handleGetHashedPassword = async () => {
    const { success, data, error } = await axios.post(
      "/api/user/hash-password",
      {
        password: password,
      }
    );
    if (success) {
      setHashedPassword(data.result);
    } else {
      console.log(error);
    }
  };

  const loadUserList = React.useCallback(async () => {
    const { success, data, error } = await getUserList();
    if (success) {
      setUserList(data);
    } else {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    loadUserList()
  }, [loadUserList]);

  return (
    <div>
      <Container>
        <Row style={{marginTop: "5px", marginBottom: "5px"}}>
          <h1>User Management</h1>
        </Row>
        <Row style={{marginTop: "5px", marginBottom: "5px"}}>
          <Col>Password</Col>
          <Col>
            <input
              type="text"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter password"
            />
          </Col>
          <Col>
            <Button onClick={handleGetHashedPassword}>
              Get Hashed Password
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: "5px", marginBottom: "5px"}}>
          <Col xs={4}>Result</Col>
          <Col xs={8} style={{border: "1px solid black"}}>{hashedPassword}</Col>
        </Row>
        <Row>
          <GenericTable columns={userTableColumns} rows={userList || []} />
        </Row>
      </Container>
    </div>
  );
};

export default ManageUser;
