import axios from "axios";
import { getDefaultRequestHeaders } from "utils";

export const getUserList = async () => {
  try {
    const res = await axios.get("/api/user/list", {
      headers: await getDefaultRequestHeaders(true),
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const res = await axios.put(
      "/api/user/change-password",
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};
