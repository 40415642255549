import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "routes";
import { renderRoutes } from "utils/layout";
import { selectUser } from "global_store/features/authen/authenSlice";
import { useSelector } from "react-redux";
import NotFoundPage from "pages/page/NotFoundPage";

const Page = () => {
  const user = useSelector(selectUser);
  return (
    <div>
      <Switch>
          {renderRoutes(routes, "/page", user?.role_code || "")}
          <Route path="/page/*" component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default Page;
