import jwtDecode from "jwt-decode";
import { store } from "global_store/store";
import { getAccessToken } from "services/authen";
import {
  setAccessToken,
  setShowSessionExpiredModal,
} from "global_store/features/authen/authenSlice";

export function getToken() {
  return store.getState().authen.accessToken;
}

export function isTokenValid(token) {
  if (!token) {
    return false;
  }
  try {
    const decoded_jwt = jwtDecode(token);
    const current_time = Date.now().valueOf() / 1000;
    return decoded_jwt.exp > current_time;
  } catch (error) {
    return false;
  }
}

export async function renewAccessToken() {
  /* Get Access Token */
  const { success, data } = await getAccessToken();
  if (success) {
    store.dispatch(setAccessToken(data.access_token));
  } else {
    console.log(data);
    store.dispatch(setShowSessionExpiredModal(true));
  }
}
