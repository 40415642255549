import React from "react";
import {
  Button,
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Form,
  Modal,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import routes from "routes";
import { useHistory } from "react-router-dom";
import { requestLogout } from "services/authen";
import {
  setUser,
  setAccessToken,
  selectUser,
} from "global_store/features/authen/authenSlice";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "services/user";

const AdminNavbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const [modalShowPassword, setModalShowPassword] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const toggleModalShowPassword = () => {
    setModalShowPassword(!modalShowPassword);
  };

  const handleChangePassword = async () => {
    const { success, error } = await changePassword(
      oldPassword,
      newPassword
    );
    if (success) {
      console.log("Password successfully changed");
      setModalShowPassword(false);
    } else {
      console.log(error);
    }
  };

  const handleLogout = async () => {
    await requestLogout();
    dispatch(setUser(null));
    dispatch(setAccessToken(null));
    history.push("/page/admin-login");
  };

  const renderDropdown = (layout) => {
    return routes.map((prop, key) => {
      if (
        user &&
        prop.layout === layout &&
        prop.roles.includes(user.role_code)
      ) {
        return (
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link dropdown-item"
            activeClassName="active"
            key={key}
          >
            {prop.name}
          </NavLink>
        );
      }
      return null;
    });
  };

  return (
    <div>
      <Navbar collapseOnSelect bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => history.push("/console/home")}>
            แพทยสภา
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="console" id="basic-nav-dropdown">
                {renderDropdown("/console")}
              </NavDropdown>
            </Nav>
            {user ? (
              <>
                <Nav>
                  <Nav.Link
                    onClick={() => {
                      toggleModalShowPassword();
                    }}
                  >
                    {user.username}
                  </Nav.Link>
                </Nav>
                <Button block="true" variant="light" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            ) : (
              "Not logged In"
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        show={modalShowPassword}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          toggleModalShowPassword();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group size="lg" controlId="oldpassword">
            <Form.Label>Current Password : </Form.Label>
            <Form.Control
              autoFocus
              value={oldPassword}
              type="password"
              placeholder="Enter Current password"
              onChange={(evt) => {
                setOldPassword(evt.target.value);
              }}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="newpassword">
            <Form.Label>New password : </Form.Label>
            <Form.Control
              type="password"
              value={newPassword}
              placeholder="Enter New password"
              onChange={(evt) => {
                setNewPassword(evt.target.value);
              }}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="confirmpassword">
            <Form.Label>Confirm password : </Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              placeholder="Enter Confirm password"
              onChange={(evt) => {
                setConfirmPassword(evt.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              toggleModalShowPassword();
            }}
          >
            Close
          </Button>
          <Button onClick={handleChangePassword}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminNavbar;
