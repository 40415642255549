import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Unlock from "@iconify/icons-fa-solid/unlock";

const LoginButton = ({ isProcessing }) => {
  return (
    <>
      <Button
        size="lg"
        type="submit"
        disabled={isProcessing}
        style={{ background: "#1B2942" }}
      >
        {isProcessing ? (
          <Spinner animation="border" variant="light" />
        ) : (
          <>
            <Icon icon={Unlock} inline className="mx-2"/>
            Login
          </>
        )}
      </Button>
    </>
  );
};

export default LoginButton;
