import React from "react";
import { Icon } from "@iconify/react";
import QuestionCircle from "@iconify/icons-fa-solid/question-circle";

const ForgetPincode = () => {
  return (
    <>
      <a
        href="https://doctor.tmc.or.th/Auth/ForgotPincode"
        className="btnForget"
      >
        <Icon icon={QuestionCircle} inline className="mx-1" />
        ลืมรหัส PIN CODE
      </a>
    </>
  );
};

export default ForgetPincode;
