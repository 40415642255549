import React from "react";
import { Card, Container, Alert, Form } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { requestAdminLogin } from "services/authen";
import LoginButton from "components/Button/LoginButton";
import {
  setAccessToken,
  setUser,
  selectUser,
} from "global_store/features/authen/authenSlice";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

const schema = yup.object().shape({
  username: yup.string().required("โปรดใส่ Username"),
  password: yup.string().required("โปรดใส่ Password"),
});

const AdminLogin = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const history = useHistory();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [errorDetails, setErrorDetails] = React.useState(null);

  const onSubmit = async (data) => {
    setLoginFailed(false);
    setIsProcessing(true);
    const { success, data: res, error } = await requestAdminLogin(data);
    setIsProcessing(false);
    if (success && res) {
      const tokenContent = jwtDecode(res.access_token);
      dispatch(setAccessToken(res.access_token));
      dispatch(setUser(tokenContent.user));
      history.push("/console/manage-client");
    } else {
      console.log(error);
      setErrorDetails(error);
      setLoginFailed(true);
    }
  };

  if (user && user.role_code === "admin") {
    return <Redirect to="/console/manage-client" />;
  }

  return (
    <Container>
      <Card className="admin-login-card">
        <Card.Body>
          <Card.Title className="text-center">AUTHEN TMC Service</Card.Title>
          {loginFailed && (
            <Alert
              variant="danger"
              style={{
                fontSize: "12px",
              }}
            >
              Username หรือ Password ไม่ถูกต้อง
              {errorDetails && (
                <p>{`รายละเอียด : ${errorDetails.detail}`}</p>
              )}
            </Alert>
          )}

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group size="lg" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                placeholder="Username"
                aria-label="Username"
                type="Text"
                {...register("username")}
                isInvalid={errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group size="lg" controlId="password" className="mt-2">
              <Form.Label>Password</Form.Label>
              <Form.Control
                placeholder="Password"
                aria-label="Password"
                type="password"
                {...register("password")}
                isInvalid={errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2 my-3">
              <LoginButton isProcessing={isProcessing} />
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminLogin;
