import React from 'react'
import Alert from "react-bootstrap/Alert";

const ContinueOnDevice = () => {
    return (
        <div>
            <Alert variant="success">Continue on Device</Alert>
        </div>
    )
}

export default ContinueOnDevice
