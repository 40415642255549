import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  selectShowSessionExpiredModal,
  setShowSessionExpiredModal,
} from "global_store/features/authen/authenSlice";
import { useHistory } from "react-router-dom";
import { requestLogout } from "services/authen";
import {
  setUser,
  setAccessToken,
  selectUser
} from "global_store/features/authen/authenSlice";

const SessionExpiredModal = () => {
  const dispatch = useDispatch();
  const showSessionExpiredModal = useSelector(selectShowSessionExpiredModal);
  const history = useHistory();
  const user = useSelector(selectUser);

  const handleHide = () => {
    dispatch(setShowSessionExpiredModal(false));
    requestLogout();
    dispatch(setUser(null));
    dispatch(setAccessToken(null));
    if(user && user.role_code === "doctor"){
      history.push("/page/doctor-login");
    } else{
      history.push("/page/admin-login");
    }
  };

  return (
    <Modal
      show={showSessionExpiredModal}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          กรุณาเข้าสู่ระบบใหม่
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          ไม่พบการใช้งานของคุณเป็นเวลา 15 นาที เพื่อความปลอดภัย กรุณาล็อคอินใหม่อีกครั้ง
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionExpiredModal;
