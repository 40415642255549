import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "global_store/features/authen/authenSlice";
import RequireLoginPage from "pages/page/RequireLoginPage";

const PrivateRoute = ({
  path,
  component
}) => {
  const user = useSelector(selectUser);
  if (user) {
    return <Route path={path} component={component} />;
  } else {
    return <RequireLoginPage />;
  }
};

export default PrivateRoute;
