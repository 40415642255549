import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Redirect } from "react-router-dom";
import { Card, Form, InputGroup, Alert } from "react-bootstrap";
import {
  authorizeQRLogin,
  claimQRLogin,
  requestDoctorLogin,
} from "services/authen";
import { Icon } from "@iconify/react";
import UserAlt from "@iconify/icons-fa-solid/user-alt";
import Key from "@iconify/icons-fa-solid/key";
import logo_md from "assets/img/mdeServ2.png";
import Footer from "components/Footer/Footer";
import LoginButton from "components/Button/LoginButton";
import ForgetPincode from "components/Button/ForgetPincode";
import NoPinCode from "components/Button/NoPinCode";
import {
  setAccessToken,
  setUser,
  selectUser,
} from "global_store/features/authen/authenSlice";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

const schema = yup.object().shape({
  username: yup.string().required("โปรดใส่เลข ว."),
  password: yup.string().required("โปรดใส่ PIN CODE"),
});

const DoctorLogin = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [groupId, setGroupId] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const claimQRCodeByGroupId = React.useCallback(async (groupId) => {
    const { data: res } = await claimQRLogin(groupId);
    return res;
  }, []);

  React.useEffect(() => {
    const groupId = new URLSearchParams(window.location.search).get("group_id");
    setGroupId(groupId);
    if (groupId) {
      claimQRCodeByGroupId(groupId);
    }
  }, [claimQRCodeByGroupId]);

  const onAuthorizeQRLogin = async (groupId) => {
    await authorizeQRLogin(groupId);
  };

  const onSubmit = async (data) => {
    setLoginFailed(false);
    setIsProcessing(true);
    const {
      success,
      data: res,
      error,
    } = await requestDoctorLogin(data, groupId);
    if (success && res) {
      const tokenContent = jwtDecode(res.access_token);
      dispatch(setAccessToken(res.access_token));
      dispatch(setUser(tokenContent.user));
    } else {
      console.log(error);
      setLoginFailed(true);
    }
    setIsProcessing(false);
  };

  React.useEffect(() => {
    if (groupId && user) {
      onAuthorizeQRLogin(groupId);
    }
  }, [groupId, user]);

  if (user && user.role_code === "doctor") {
    if (groupId) {
      // Activate Login
      return <Redirect to="/page/continue-on-device" />;
    }
    return <Redirect to="/console/doctor-profile" />;
  }

  return (
    <div className="bg-signin">
      <div className="v-center">
        <Card className="text-center">
          <Card.Header>
            <div className="sectionCenter">
              <img src={logo_md} alt="logo_md" />
            </div>
          </Card.Header>
          <Card.Body>
            {loginFailed && (
              <Alert
                variant="danger"
                style={{
                  fontSize: "12px",
                }}
              >
                <strong>ข้อมูลไม่ถูกต้อง !</strong> DOCTOR ID. หรือรหัส PIN CODE
                ไม่ถูกต้อง
              </Alert>
            )}

            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputGroup className="mb-3">
                <InputGroup.Text id="username">
                  <Icon icon={UserAlt} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Doctor ID (เลข ว.)"
                  aria-label="Username"
                  type="Text"
                  {...register("username")}
                  isInvalid={errors.username}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text id="password">
                  <Icon icon={Key} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="PIN CODE"
                  aria-label="PINCODE"
                  type="password"
                  {...register("password")}
                  isInvalid={errors.password}
                />
              </InputGroup>
              <div className="d-grid gap-2">
                <LoginButton isProcessing={isProcessing} />
              </div>
              <div className="my-2">
                <ForgetPincode />
              </div>
              <div className="mt-5 mb-1">
                <NoPinCode />
              </div>
            </Form>
          </Card.Body>
          <Card.Footer>
            <Footer />
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default DoctorLogin;
