import { format } from "date-fns";
import React from "react";
import { GrPowerReset } from "react-icons/gr";

const QRTimer = ({ onTimeout, onReset, durationSeconds }) => {
  const [elaspeTimeSeconds, setElapseTimeSeconds] = React.useState(0);

  const handleTimeout = () => {
    if (onTimeout) {
      onTimeout();
    }
  };

  const tick = () => {
    const remainingTime = durationSeconds - elaspeTimeSeconds;
    if (remainingTime > 0) {
      setElapseTimeSeconds(elaspeTimeSeconds + 1);
    } else if (remainingTime === 0) {
      handleTimeout();
      setElapseTimeSeconds(elaspeTimeSeconds + 1);
    }
  };

  const handleReset = () => {
    if (onReset) {
      onReset();
    }
    setElapseTimeSeconds(0);
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerId);
    };
  });

  const renderTime = () => {
    const date = new Date();
    const remainingTime = durationSeconds - elaspeTimeSeconds;
    if (remainingTime > 0) {
      date.setMinutes(Math.floor(remainingTime / 60));
      date.setSeconds(remainingTime % 60);
    } else {
      date.setMinutes(0);
      date.setSeconds(0);
    }
    return format(date, "mm:ss");
  };

  return (
    <div>
      {durationSeconds <= elaspeTimeSeconds ? (
        <>
          <p style={{ color: "red" }}>
            <GrPowerReset style={{ cursor: "pointer" }} onClick={handleReset} />{" "}
            QR code หมดอายุ
          </p>
        </>
      ) : (
        `กรุณา scan ภายใน ${renderTime()} นาที`
      )}
    </div>
  );
};

export default QRTimer;
