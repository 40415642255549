import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import UserAlt from "@iconify/icons-fa-solid/user-alt";

const NoPinCode = () => {
  return (
    <div className="pt-2">
      <div className="sectionLine ">
        <span>ยังไม่มี PIN CODE แพทยสภา</span>
      </div>
      <div className="m-4">
        <Button
          href="https://doctor.tmc.or.th/Auth/ValidateForm"
          className="btnCreate"
        >
          <Icon icon={UserAlt} inline className="mx-2"/>
          ลงทะเบียนตั้งค่า PIN CODE
        </Button>
      </div>
    </div>
  );
};

export default NoPinCode;
