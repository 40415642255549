import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { selectUser } from "global_store/features/authen/authenSlice";
import { useSelector } from "react-redux";

const DoctorProfile = () => {
  const user = useSelector(selectUser);
  return (
    <div>
      <div className="App container py-3">
        <div className="Home" style={{ minHeight: 300 }}>
          <div style={{ display: "block" }}>
            <Row>
              <Col md={2}>
                <Form.Label>Title : </Form.Label>
                <Form.Control
                  value={user?.other_info.prefix || ""}
                  readOnly
                />
              </Col>
              <Col md={5}>
                <Form.Label>Firstname : </Form.Label>
                <Form.Control
                  value={user?.other_info.firstname || ""}
                  readOnly
                />
              </Col>
              <Col md={5}>
                <Form.Label>Lastname : </Form.Label>
                <Form.Control
                  value={user?.other_info.lastname || ""}
                  type="text"
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
