import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import { renderRoutes } from "utils/layout";
import NotFoundPage from "pages/page/NotFoundPage";
import DoctorNavbar from "components/Navbar/DoctorNavbar";
import { selectUser } from "global_store/features/authen/authenSlice";
import { useSelector } from "react-redux";
import AdminNavbar from "components/Navbar/AdminNavbar";

const Console = () => {
  const user = useSelector(selectUser);

  return (
    <div>
      {user && user.role_code === "doctor" ? <DoctorNavbar /> : <AdminNavbar />}
      <Switch>
        {renderRoutes(routes, "/console", user?.role_code || "")}
        {user && user.role_code === "doctor" ? <Redirect exact path="/console" to="/console/doctor-profile" /> : <Redirect exact path="/console" to="/console/manage-client" />}
        <Route path="/console/*" component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default Console;
