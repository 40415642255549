import { isTokenValid } from "utils/authen";
import { getToken, renewAccessToken } from "utils/authen";

export async function getDefaultRequestHeaders(includeAuthorization) {
  let header = {
    'Cache-Control': `no-cache`,
    'Pragma': `no-cache`,
    'Expires': `0`,
    'Access-Control-Allow-Origin': `*`
  };
  if (includeAuthorization) {
    if (!isTokenValid(getToken())) {
      await renewAccessToken();
    }
    return { ...header, Authorization: `Bearer ${getToken()}` };
  }
  return header;
}

