import React from "react";
import { Button, Navbar, Nav, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { requestLogout } from "services/authen";
import {
  setUser,
  setAccessToken,
  selectUser,
} from "global_store/features/authen/authenSlice";
import { useDispatch, useSelector } from "react-redux";

const DoctorNavbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);

  const handleLogout = async () => {
    await requestLogout();
    dispatch(setUser(null));
    dispatch(setAccessToken(null));
    history.push("/page/doctor-login");
  };

  return (
    <div>
      <Navbar collapseOnSelect bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => history.push("/console/doctor-profile")}>
            แพทยสภา
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {user ? (
              <>
                <Nav>
                  <Navbar.Text>{user.username}</Navbar.Text>
                </Nav>
                <Button block="true" variant="light" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            ) : (
              "Not logged In"
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default DoctorNavbar;
