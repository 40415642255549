import axios from "axios";
import { getDefaultRequestHeaders } from "utils";

export const getAPIKeyList = async () => {
  try {
    const res = await axios.get("/api/api-key/list", {
      headers: await getDefaultRequestHeaders(true),
    });
    return { success: true, data: res.data, error: null };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const createAPIKey = async (apiKeyName) => {
  try {
    const res = await axios.post(
      "/api/api-key/create",
      {
        api_key_name: apiKeyName,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};

export const updateAPIKey = async (apiKeyId, active) => {
  try {
    const res = await axios.put(
      "/api/api-key/update",
      {
        id: apiKeyId,
        active: active,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
      error: err.response.data,
    };
  }
};
