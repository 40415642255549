import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  accessToken: null,
  showSessionExpiredModal: false,
};

export const authenSlice = createSlice({
  name: "authen",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setShowSessionExpiredModal: (state, action) => {
      state.showSessionExpiredModal = action.payload;
    },
  },
});

export const { setUser, setAccessToken, setShowSessionExpiredModal } = authenSlice.actions;

export const selectUser = (state) => state.authen.user;
export const selectAccessToken = (state) => state.authen.accessToken;
export const selectShowSessionExpiredModal = (state) => state.authen.showSessionExpiredModal;

export default authenSlice.reducer;
