import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";

export const renderRoutes = (routes, layout, currentRole) => {
  const mappedRoutes = routes.map((prop, key) => {
    if (prop.collapse) {
      return renderRoutes(prop.views);
    }
    if (prop.layout === layout) {
      if (prop.isPrivate) {
        if (prop.roles.includes(currentRole)) {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      } else {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
    } else {
      return null;
    }
  });
  return mappedRoutes.filter((route) => route);
};

export const getPageName = (routes) => {
  for (let route of routes) {
    if (window.location.pathname.startsWith(route.layout + route.path)) {
      return route.name;
    }
  }
  return "Unknown";
};

export const getPageProperties = (routes) => {
  for (let route of routes) {
    if (window.location.pathname.startsWith(route.layout + route.path)) {
      return route.properties;
    }
  }
  return null;
};
