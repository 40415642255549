import React from "react";
import { FaEye } from "react-icons/fa";

const SecretText = ({ text }) => {
  const [hideText, setHideText] = React.useState(true);

  const toggleHideText = () => {
    setHideText(!hideText);
  };

  return (
    <p style={{ WebkitTextSecurity: hideText ? "disc" : "none" }}>
      {text}
      <FaEye
        onClick={toggleHideText}
        style={{
          color: "rgba(0,0,0, 0.5)",
          marginLeft: "10px",
          marginBottom: "2px",
          cursor: "pointer",
        }}
      />
    </p>
  );
};

export default SecretText;
