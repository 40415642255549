import React from "react";
import Alert from "react-bootstrap/Alert";

const NotFoundPage = () => {
  return (
    <div>
      <Alert variant="danger">404 Not Found</Alert>
    </div>
  );
};

export default NotFoundPage;
